import React from "react"
import tw from "twin.macro"
import styled from "@emotion/styled"
import Layout from "../../components/layout"

const BlogContainer = styled("div")`
  ${tw`flex justify-center px-8 mb-8 pt-24`}
`

const APG101 = () => {
  return (
    <Layout>
      <BlogContainer>
        <div className="w-full desktop:w-3/4 flex flex-row flex-wrap">
          <div className="w-full tablet:pb-4 mb-4">
            <div className="flex flex-wrap flex-row justify-center flex-grow">
              <div className="w-full flex flex-row flex-wrap justify-center">
                <div className="w-full">
                  <p className="text-3xl tablet:text-4xl tablet:tracking-wide tablet:leading-10 font-extrabold phone:leading-none text-left font-display text-gray-800 -mb-4">
                    Archeoplanetography(APG): 101
                  </p>
                  <p className="text-lg tablet:text-2xl tablet:tracking-wide tablet:leading-10 font-extrabold phone:leading-none pb-12 tablet:pb-6 text-left font-display text-gray-700">
                    by Su Walker
                  </p>
                  <hr className="h-1 border-t border-gray-400 py-4 mt-4" />
                </div>
                <div className="w-full grid grid-cols-1 desktop:grid-cols-3 gap-6">
                  <div className="w-full embed-youtube shadow-lg">
                    <iframe
                      width="560"
                      height="315"
                      loading="lazy"
                      src="https://www.youtube.com/embed/iGJIi5cN4Yc"
                      frameBorder="0"
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen"
                      title="APG 101: Lesson 1"
                    ></iframe>
                  </div>

                  <div className="w-full embed-youtube shadow-lg">
                    <iframe
                      width="560"
                      height="315"
                      loading="lazy"
                      src="https://www.youtube.com/embed/gnb90avAFaQ"
                      frameBorder="0"
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen"
                      title="APG 101 - Lesson 2: Labeling & Recording Outlined Images"
                    ></iframe>
                  </div>

                  <div className="w-full embed-youtube shadow-lg">
                    <iframe
                      width="560"
                      height="315"
                      loading="lazy"
                      src="https://www.youtube.com/embed/kpQTAKGePJ0"
                      frameBorder="0"
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen"
                      title="Archeoplanetography: Quick Illustrated Examples"
                    ></iframe>
                  </div>

                  <div className="w-full embed-youtube shadow-lg">
                    <iframe
                      width="560"
                      height="315"
                      loading="lazy"
                      src="https://www.youtube.com/embed/PsKjSeVdepo"
                      frameBorder="0"
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen"
                      title="APG: Illustrated Examples in Europe, Asia and Earth's Oceans"
                    ></iframe>
                  </div>

                  <div className="w-full embed-youtube shadow-lg">
                    <iframe
                      width="560"
                      height="315"
                      loading="lazy"
                      src="https://www.youtube.com/embed/yD-i71cVsgQ"
                      frameBorder="0"
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen"
                      title="Earth APG 3: Focus on South America"
                    ></iframe>
                  </div>

                  <div className="w-full embed-youtube shadow-lg">
                    <iframe
                      width="560"
                      height="315"
                      loading="lazy"
                      src="https://www.youtube.com/embed/cIi_Av1H4YY"
                      frameBorder="0"
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen"
                      title="APG 4 The Sasquatch of the Allegash River, Maine (USA)"
                    ></iframe>
                  </div>

                  <div className="w-full embed-youtube shadow-lg">
                    <iframe
                      width="560"
                      height="315"
                      loading="lazy"
                      src="https://www.youtube.com/embed/VjbA2czIg2k"
                      frameBorder="0"
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen"
                      title="APG 5 Sagadahoc County, Maine Sasquatch Territory"
                    ></iframe>
                  </div>

                  <div className="w-full embed-youtube shadow-lg">
                    <iframe
                      width="560"
                      height="315"
                      loading="lazy"
                      src="https://www.youtube.com/embed/7saNew0dY2o"
                      frameBorder="0"
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen"
                      title="APG 7 Puma Punku"
                    ></iframe>
                  </div>

                  <div className="w-full embed-youtube shadow-lg">
                    <iframe
                      width="560"
                      height="315"
                      loading="lazy"
                      src="https://www.youtube.com/embed/rcK5ntfnXiA"
                      frameBorder="0"
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen"
                      title="APG 6: Gobekli Tepe"
                    ></iframe>
                  </div>

                  <div className="w-full embed-youtube shadow-lg">
                    <iframe
                      width="560"
                      height="315"
                      loading="lazy"
                      src="https://www.youtube.com/embed/ELXMs3MXr4o"
                      frameBorder="0"
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen"
                      title="APG 8 African Eyes"
                    ></iframe>
                  </div>

                  <div className="w-full embed-youtube shadow-lg">
                    <iframe
                      width="560"
                      height="315"
                      loading="lazy"
                      src="https://www.youtube.com/embed/AYJ5qqbdcg0"
                      frameBorder="0"
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen"
                      title="APG 9 Mt Shasta"
                    ></iframe>
                  </div>

                  <div className="w-full embed-youtube shadow-lg">
                    <iframe
                      width="560"
                      height="315"
                      loading="lazy"
                      src="https://www.youtube.com/embed/ip_11AtHgLA"
                      frameBorder="0"
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen"
                      title="APG 10 Global Sized Images"
                    ></iframe>
                  </div>

                  <div className="w-full embed-youtube shadow-lg">
                    <iframe
                      width="560"
                      height="315"
                      loading="lazy"
                      src="https://www.youtube.com/embed/sNw5VNDuxJI"
                      frameBorder="0"
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen"
                      title="APG 11 Australia's Pilot"
                    ></iframe>
                  </div>

                  <div className="w-full embed-youtube shadow-lg">
                    <iframe
                      width="560"
                      height="315"
                      loading="lazy"
                      src="https://www.youtube.com/embed/DjmvSyJch0o"
                      frameBorder="0"
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen"
                      title="APG 12 Skinwalker Ranch"
                    ></iframe>
                  </div>

                  <div className="w-full embed-youtube shadow-lg">
                    <iframe
                      width="560"
                      height="315"
                      loading="lazy"
                      src="https://www.youtube.com/embed/HOXmo8iuqxU"
                      frameBorder="0"
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen"
                      title="APG 13 Skinwalker Ranch Close Up"
                    ></iframe>
                  </div>

                  <div className="w-full embed-youtube shadow-lg">
                    <iframe
                      width="560"
                      height="315"
                      loading="lazy"
                      src="https://www.youtube.com/embed/6sFtS1vrOJM"
                      frameBorder="0"
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen"
                      title="APG 14 Eyeing France"
                    ></iframe>
                  </div>

                  <div className="w-full embed-youtube shadow-lg">
                    <iframe
                      width="560"
                      height="315"
                      loading="lazy"
                      src="https://www.youtube.com/embed/sTHZGnIHdqY"
                      frameBorder="0"
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen"
                      title="APG 15 Europe"
                    ></iframe>
                  </div>

                  <div className="w-full embed-youtube shadow-lg">
                    <iframe
                      width="560"
                      height="315"
                      loading="lazy"
                      src="https://www.youtube.com/embed/SYzFPs2iIVU"
                      frameBorder="0"
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen"
                      title="APG 16 Hokkaido, Japan"
                    ></iframe>
                  </div>

                  <div className="w-full embed-youtube shadow-lg">
                    <iframe
                      width="560"
                      height="315"
                      loading="lazy"
                      src="https://www.youtube.com/embed/pkrnl9pCoY0"
                      frameBorder="0"
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen"
                      title="APG 17 Shalako Zuni, NM"
                    ></iframe>
                  </div>

                  <div className="w-full embed-youtube shadow-lg">
                    <iframe
                      width="560"
                      height="315"
                      loading="lazy"
                      src="https://www.youtube.com/embed/8lWlc-gpplw"
                      frameBorder="0"
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen"
                      title="Archeoplanetography"
                    ></iframe>
                  </div>

                  <div className="w-full embed-youtube shadow-lg">
                    <iframe
                      width="560"
                      height="315"
                      loading="lazy"
                      src="https://www.youtube.com/embed/ePTODglWGdw"
                      frameBorder="0"
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen"
                      title="APG 18 China"
                    ></iframe>
                  </div>

                  <div className="w-full embed-youtube shadow-lg">
                    <iframe
                      width="560"
                      height="315"
                      loading="lazy"
                      src="https://www.youtube.com/embed/WD1dqD8j4GI"
                      frameBorder="0"
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen"
                      title="APG 19 Russia"
                    ></iframe>
                  </div>

                  <div className="w-full embed-youtube shadow-lg">
                    <iframe
                      width="560"
                      height="315"
                      loading="lazy"
                      src="https://www.youtube.com/embed/g-yaSh66Zxc"
                      frameBorder="0"
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen"
                      title="APG 21 Bremerton, WA"
                    ></iframe>
                  </div>

                  <div className="w-full embed-youtube shadow-lg">
                    <iframe
                      width="560"
                      height="315"
                      loading="lazy"
                      src="https://www.youtube.com/embed/fPVZelQdjpY"
                      frameBorder="0"
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen"
                      title="APG 22 AZ:NM Desert Rider"
                    ></iframe>
                  </div>

                  <div className="w-full embed-youtube shadow-lg">
                    <iframe
                      width="560"
                      height="315"
                      loading="lazy"
                      src="https://www.youtube.com/embed/v56NMgP_fWQ"
                      frameBorder="0"
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen"
                      title="APG 23 Bosnian Pyramid"
                    ></iframe>
                  </div>

                  <div className="w-full embed-youtube shadow-lg">
                    <iframe
                      width="560"
                      height="315"
                      loading="lazy"
                      src="https://www.youtube.com/embed/U4lsHrjq0VI"
                      frameBorder="0"
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen"
                      title="APG 25 Colorado Bigfoot"
                    ></iframe>
                  </div>

                  <div className="w-full embed-youtube shadow-lg">
                    <iframe
                      width="560"
                      height="315"
                      loading="lazy"
                      src="https://www.youtube.com/embed/E8nu1Yb2rl4"
                      frameBorder="0"
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen"
                      title="APG 26 Bayan Nur Man Inner Mongolia, China Asia"
                    ></iframe>
                  </div>

                  <div className="w-full embed-youtube shadow-lg">
                    <iframe
                      width="560"
                      height="315"
                      loading="lazy"
                      src="https://www.youtube.com/embed/VRlAr-7TMSo"
                      frameBorder="0"
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen"
                      title="APG 27 Ngari Tibet, Mapam Yumco Lake Details"
                    ></iframe>
                  </div>

                  <div className="w-full embed-youtube shadow-lg">
                    <iframe
                      width="560"
                      height="315"
                      loading="lazy"
                      src="https://www.youtube.com/embed/8HyEH3W5VRY"
                      frameBorder="0"
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen"
                      title="APG 28 North America Faces"
                    ></iframe>
                  </div>

                  <div className="w-full embed-youtube shadow-lg">
                    <iframe
                      width="560"
                      height="315"
                      loading="lazy"
                      src="https://www.youtube.com/embed/Mn4lTCe258w"
                      frameBorder="0"
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen"
                      title="APG 29 - 3 Figures in the Americas"
                    ></iframe>
                  </div>

                  <div className="w-full embed-youtube shadow-lg">
                    <iframe
                      width="560"
                      height="315"
                      loading="lazy"
                      src="https://www.youtube.com/embed/w7ULhZSxE0Y"
                      frameBorder="0"
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen"
                      title="Central USA: The P'nti & The Sasquatch"
                    ></iframe>
                  </div>

                  <div className="w-full embed-youtube shadow-lg">
                    <iframe
                      width="560"
                      height="315"
                      loading="lazy"
                      src="https://www.youtube.com/embed/isg7FUwWyNk"
                      frameBorder="0"
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen"
                      title="APG 30 Kentucky's Big Cat"
                    ></iframe>
                  </div>

                  <div className="w-full embed-youtube shadow-lg">
                    <iframe
                      width="560"
                      height="315"
                      loading="lazy"
                      src="https://www.youtube.com/embed/F69byl8x6pA"
                      frameBorder="0"
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen"
                      title="APG 31 Google Mars Maiden"
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </BlogContainer>
    </Layout>
  )
}

export default APG101
